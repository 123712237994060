import {
    CitizenshipType,
    DocumentType,
    MemberStatusType,
    PublicStatusType,
    PurposesOfActivityTranslate,
    RelationshipOfCompanyTranslate,
} from '@dltru/dfa-models'
import { DefaultOptionType } from 'rc-select/lib/Select'

export const citizenshipOptions: DefaultOptionType[] = [
    { value: CitizenshipType.RU, label: 'Российская Федерация' },
]

export const publicStatusOptions: DefaultOptionType[] = [
    {
        value: PublicStatusType.notPublic,
        label: 'Не являюсь публичным должностным лицом или родственником такого лица',
    },
    {
        value: PublicStatusType.public,
        label: 'Являюсь публичным должностным лицом',
    },
    {
        value: PublicStatusType.related,
        label: 'Являюсь родственником, супругом (супругой) публичного должностного лица',
    },
]

export const documentTypeOptions: DefaultOptionType[] = [
    { value: DocumentType.passport, label: 'Паспорт' },
]

export const relationshipOfCompanyOptions: DefaultOptionType[] = Object.entries(
    RelationshipOfCompanyTranslate,
).map(([value, label]) => ({ value, label }))

export const purposesOfActivityOptions: DefaultOptionType[] = Object.entries(
    PurposesOfActivityTranslate,
).map(([value, label]) => ({ value, label }))
